<template>
  <div class="dashboard-search" v-click-outside="handleOutsideClick">
    <div class="dashboard-search__icon">
      <img src="@/assets/img/icons/dashboard/search.svg" alt="search" />
    </div>
    <input type="text" v-model="computedSearchQuery" class="dashboard-search__input" ref="input" />
    <app-button transparent @click="dismissSearch">
      <template #icon>
        <inline-svg :src="require('@/assets/img/icons/dashboard/close.svg')" />
      </template>
    </app-button>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import AppButton from '@/components/elements/AppButton'
export default {
  name: 'DashboardSearch',
  components: { AppButton, InlineSvg },
  props: {
    searchQuery: {
      type: String,
      default: null
    }
  },
  methods: {
    dismissSearch() {
      this.$emit('dismissSearch')
    },
    focusInput() {
      this.$refs.input.focus()
    },
    handleOutsideClick() {
      if (!this.searchQuery) {
        this.dismissSearch()
      }
    }
  },
  computed: {
    computedSearchQuery: {
      get() {
        return this.searchQuery
      },
      set(val) {
        this.$emit('updateSearchQuery', val)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.dashboard-search
  width: 100%
  display: grid
  grid-template-columns: rem(30) 1fr rem(30)
  background: $white
  border: rem(1) solid $ec
  box-sizing: border-box
  border-radius: rem(4)
  padding: rem(5)
  height: rem(40)
  &__icon
    display: flex
    align-items: center
    justify-content: center
    filter: invert(65%) sepia(8%) saturate(7%) hue-rotate(17deg) brightness(93%) contrast(87%)
  &__input
    width: 100%
    border: none
    padding: 0 rem(5)
    box-sizing: border-box
    outline: none
    font-family: $main-font
    overflow: hidden
    text-overflow: ellipsis
  .app-button
    width: 100%
</style>
